<template>
  <div>
    <!-- <script @load="authLoaded" src="https://secure.aadcdn.microsoftonline-p.com/lib/1.0.15/js/adal.min.js" integrity="sha384-lIk8T3uMxKqXQVVfFbiw0K/Nq+kt1P3NtGt/pNexiDby2rKU6xnDY8p16gIwKqgI" crossorigin="anonymous" type="application/javascript"></script> -->
    <script type="application/javascript" @load="authLoaded" src="https://alcdn.msauth.net/browser/2.14.2/js/msal-browser.min.js"></script>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { EventBus } from '@/plugins/eventbus.js';
import accountService from "@/services/accountService";
import tenants from '@/data/tenants.config'
import i18n from '@/i18n'
// https://docs.microsoft.com/en-us/javascript/api/overview/msteams-client?view=msteams-client-js-latest
//import * as microsoftTeams from "@microsoft/teams-js";
const tenant = tenants.current();
//const microsoftTeams = window.microsoftTeams;

export default {
  components: {
  },
  props: {
    teamsWrapper: Object
  },
  data: () => ({
    authConfig: {  
      clientId: process.env.VUE_APP_MICROSOFT_CLIENT_ID,
      redirectUri: window.location.origin + "/apps/teams-v2/auth/end-microsoft",
      cacheLocation: "localStorage",
      navigateToLoginRequestUrl: false,
    },
  }),


  methods: {
    async authLoaded() {
      this.tryCompleteFlow();
    },
    async tryCompleteFlow() {
      console.log('teams available in auth end', this.teamsWrapper, window.AuthenticationContext);
      if (this.teamsWrapper.microsoftTeams && window.AuthenticationContext) {
        let authContext = new window.AuthenticationContext(this.authConfig);
        if (authContext.isCallback(window.location.hash)) {
            authContext.handleWindowCallback(window.location.hash);
            // Only call notifySuccess or notifyFailure if this page is in the authentication popup
            if (window.opener) {
                if (authContext.getCachedUser()) {
                    console.log('auth callback with user ', authContext.getCachedUser());
                    this.teamsWrapper.microsoftTeams.authentication.notifySuccess();
                } else {
                    console.log('auth callback with error ', authContext.getLoginError());
                    this.teamsWrapper.microsoftTeams.authentication.notifyFailure(authContext.getLoginError());
                }
            }
        }      
      }
    },
  },
  computed: {

  },
  watch: {
    teamsWrapper() {
      this.tryCompleteFlow();
    }
  }
};
</script>
<style lang="scss" scoped>
  

</style>

